import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchUsuarios(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('usuarios', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCredor(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`usuarios/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUsuario(ctx, usuarioData) {
      return new Promise((resolve, reject) => {
        axios
          .post('usuarios', { ...usuarioData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
