import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsuarioList() {
  // Use toast
  const toast = useToast()

  const refUsuarioListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'name', label: "Nome"},
    { key: 'email'},
    { key: 'actions', label: "Ações" },
  ]
  const perPage = ref(10)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')

  const dataMeta = ref({})

  const refetchData = () => {
    refUsuarioListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const fetchUsuarios = (ctx, callback) => {
    store
      .dispatch('app-usuario/fetchUsuarios', {
        // q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
      })
      .then(response => {
        const { data, meta } = response.data     
        callback(data)
        dataMeta.value = meta
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  
  return {
    fetchUsuarios,
    tableColumns,
    perPage,
    currentPage,
    dataMeta,
    perPageOptions,
    searchQuery,
    refUsuarioListTable,

    refetchData,
  }
}
